import React from 'react';
import { get, uniqueId } from 'lodash';
import dayjs from 'dayjs';

import {
  validToken,
  getUserClaims,
  removeAuthToken,
  updateUserClaims,
} from '../auth';
import { ApiServiceServerless } from '../xhr_libs';

const claims = validToken() ? getUserClaims() : '';

const emptyOrgDetails = {
  id: null,
  name: '',
  licenses: [],
  role: '',
  portfolios: [],
};

const emptyState = {
  userId: null,
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  authRefreshFlag: null,
  userFullName: '',
  userPortfolios: [],
  userSubscriptions: [],
  userOrganizations: [],
  userSelectedPortfolio: {
    id: -1,
    name: '',
  },
  userSelectedOrganization: { id: null, name: '' },
  userSelectedOrganizationDetails: { ...emptyOrgDetails },
  userOrganizationKey: 0,
  userPortfolioKey: 0,
  userSubscriptionKey: 0,
  userVersion: -1,
  toasts: [],
  disableOrganizationSelect: false,
  updatingProfile: false,
  updatingTime: dayjs().unix(),
};

const getDefaultState = (claims) =>
  claims
    ? {
        userId: claims.userId,
        userEmail: claims.userEmail,
        userFullName: claims.userFullName,
        userFirstName: claims.userFirstName,
        userLastName: claims.userLastName,
        authRefreshFlag: claims.authRefreshFlag,
        userPortfolios: claims.portfolios,
        userSubscriptions: claims.subscriptions,
        userOrganizations: claims.organizations,
        userSelectedOrganization: claims.selectedOrganization,
        userSelectedOrganizationDetails: claims.selectedOrganizationDetails,
        userSelectedPortfolio: claims.selectedPortfolio,
        userOrganizationKey: JSON.stringify(claims.organizations).length,
        userPortfolioKey: JSON.stringify(claims.portfolios).length,
        userSubscriptionKey: JSON.stringify(claims.subscriptions).length,
        userVersion: claims.userVersion,
        toasts: [],
        disableOrganizationSelect: false,
        updatingProfile: false,
        updatingTime: dayjs().unix(),
      }
    : emptyState;

export const AppContext = React.createContext(getDefaultState(claims));

export class AppProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = getDefaultState(claims);
  }

  componentDidMount = () => {
    window.addEventListener('energytracer_storage', this.refreshToken);
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (!prevState.userId && prevState.userId !== this.state.userId) {
      this.setState(getDefaultState(validToken() ? getUserClaims() : ''));
    }
  };

  logoutUser = () => {
    this.setState({
      ...emptyState,
    });
    removeAuthToken();
  };

  refreshToken = () => {
    if (!validToken()) {
      this.logoutUser();
      return false;
    }
    const claims = getUserClaims();
    this.updateClaims(claims);
    return true;
  };

  updateClaims = (claims) => {
    if (
      claims.userEmail !== this.state.userEmail ||
      claims.userFirstName !== this.state.userFirstName ||
      claims.userLastName !== this.state.userLastName
    ) {
      this.setState({
        userEmail: claims.userEmail,
        userFirstName: claims.userFirstName,
        userLastName: claims.userLastName,
        userFullName: claims.userFullName,
      });
    }

    if (claims.authRefreshFlag !== this.state.authRefreshFlag) {
      this.setState({ authRefreshFlag: claims.authRefreshFlag });
    }

    if (claims.userId !== this.state.userId) {
      this.setState({
        userId: claims.userId,
      });
    }

    if (claims.userVersion !== this.state.userVersion) {
      this.setState({
        userVersion: claims.userVersion,
      });
    }

    if (
      claims.selectedOrganization.id !== this.state.userSelectedOrganization.id
    ) {
      this.setState({
        userSelectedOrganization: claims.selectedOrganization,
        userSelectedOrganizationDetails: claims.selectedOrganizationDetails,
      });
    }

    // name edit case to reset selected and list for resources page
    if (
      claims.selectedOrganization.name !==
      this.state.userSelectedOrganization.name
    ) {
      this.setState({
        userSelectedOrganization: claims.selectedOrganization,
        userSelectedOrganizationDetails: claims.selectedOrganizationDetails,
        userOrganizations: claims.organizations,
      });
    }

    const new_org_key = JSON.stringify(claims.organizations).length;
    if (new_org_key !== this.state.userOrganizationKey) {
      this.setState({
        userOrganizationKey: new_org_key,
        userOrganizations: claims.organizations,
      });
    }

    if (claims.selectedPortfolio.id !== this.state.userSelectedPortfolio.id) {
      this.setState({
        userSelectedPortfolio: claims.selectedPortfolio,
      });
    }

    const new_port_key = JSON.stringify(claims.portfolios).length;
    if (new_port_key !== this.state.userPortfolioKey) {
      this.setState({
        userPortfolioKey: new_port_key,
        userPortfolios: claims.portfolios,
      });
    }

    const new_sub_key = JSON.stringify(claims.subscriptions).length;
    if (new_sub_key !== this.state.userSubscriptionKey) {
      this.setState({
        userSubscriptionKey: new_sub_key,
        userSubscriptions: claims.subscriptions,
      });
    }
  };

  setUserVersion = (newVersion) => {
    // set user version
    this.setUpdatingProfile(true);
    this.setState({ userVersion: newVersion });
    let payload = { user_version: newVersion };
    ApiServiceServerless.post('/profile', payload)
      .then((res) => {
        this.resetUserInfo(res.data, 'version');
      })
      .finally(() => {
        this.setUpdatingProfile(false);
      });
  };

  setActivePortfolio = (
    portfolioData,
    instant = true,
    successCallback = () => {},
    errorCallback = () => {}
  ) => {
    this.setUpdatingProfile(true);
    // set high level portfolio details (id and name)
    let old_portfolio = this.state.userSelectedPortfolio;
    if (!portfolioData) {
      portfolioData = { id: -1, name: '' };
    }
    if (instant) {
      this.setState({
        userSelectedPortfolio: {
          id: portfolioData.id,
          name: portfolioData.name,
        },
      });
    }
    let payload = { default_portfolio: portfolioData.id };
    ApiServiceServerless.post('/profile', payload)
      .then((res) => {
        this.resetUserInfo(res.data, 'portfolio');
        successCallback();
      })
      .catch(() => {
        this.setState({ userSelectedPortfolio: old_portfolio });
        errorCallback();
      })
      .finally(() => {
        this.setUpdatingProfile(false);
      });
  };

  setActiveOrganization = (organizationData) => {
    // set high level organization details (id and name)
    const old_organization = this.state.userSelectedOrganization;

    this.setUpdatingProfile(true);
    this.setState({
      userSelectedOrganization: {
        id: organizationData.id,
        name: organizationData.name,
      },
    });
    let payload = { default_organization: organizationData.id };
    ApiServiceServerless.post('/profile', payload)
      .then((res) => {
        this.resetUserInfo(res.data, 'organization');
      })
      .catch(() => {
        this.setState({ userSelectedOrganization: old_organization });
      })
      .finally(() => {
        this.setUpdatingProfile(false);
      });
  };

  resetUserInfo = (res, key) => {
    let claims = getUserClaims();

    if (key === 'organization') {
      claims.selectedOrganization = res.userSelectedOrganization;
      claims.selectedOrganizationDetails = res.userSelectedOrganizationDetails;
      this.setState({
        userSelectedOrganization: res.userSelectedOrganization,
        userSelectedOrganizationDetails: res.userSelectedOrganizationDetails,
      });
    }

    if (key === 'portfolio') {
      claims.selectedPortfolio = res.userSelectedPortfolio;
      this.setState({
        userSelectedPortfolio: res.userSelectedPortfolio,
      });
    }

    if (key === 'version') {
      claims.userVersion = res.user.user_version;
    }

    updateUserClaims(claims);
  };

  setToasts = (toasts) => this.setState({ toasts: toasts });

  showToast = (variant, title, children) => {
    // For showing error response messages
    if (!React.isValidElement(children) && typeof children === 'object') {
      children = get(
        children,
        'response.data.detail',
        'There was an error with your request. Please contact an Administrator.'
      );

      // is response is still on object put in default error message
      if (typeof children === 'object') {
        children =
          'There was an error with your request. Please contact an Administrator.';
      }
    }

    this.setState({
      toasts: [
        ...this.state.toasts,
        {
          id: uniqueId('toast-'),
          variant: variant,
          title: title,
          children: children,
        },
      ],
    });
  };

  //used for pages with longer intial queries so that you can't cycle though orgs quickly
  setDisableOrganizationSelect = (val) => {
    this.setState({
      disableOrganizationSelect: val,
      updatingTime: dayjs().unix(),
    });
    this.checkDisabledStates();
  };

  setUpdatingProfile = (val) => {
    this.setState({ updatingProfile: val, updatingTime: dayjs().unix() });
  };

  resetDisabledStates = () => {
    this.setState({
      updatingProfile: false,
      disableOrganizationSelect: false,
      updatingTime: dayjs().unix(),
    });
  };

  checkDisabledStates = () => {
    setTimeout(() => {
      if (
        (this.state.updatingProfile || this.state.disableOrganizationSelect) &&
        dayjs().unix() - this.state.updatingTime >= 4
      ) {
        this.resetDisabledStates();
      }
    }, 4100);
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          userId: this.state.userId,
          userEmail: this.state.userEmail,
          userFullName: this.state.userFullName,
          userFirstName: this.state.userFirstName,
          userLastName: this.state.userLastName,
          authRefreshFlag: this.state.authRefreshFlag,
          userVersion: this.state.userVersion,
          userSelectedPortfolio: this.state.userSelectedPortfolio,
          userSelectedOrganization: this.state.userSelectedOrganization,
          userSelectedOrganizationDetails:
            this.state.userSelectedOrganizationDetails,
          userOrganizations: this.state.userOrganizations,
          userPortfolios: this.state.userPortfolios,
          userSubscriptions: this.state.userSubscriptions,
          rolesObject: this.props.rolesObject,
          toasts: this.state.toasts,
          setActivePortfolio: this.setActivePortfolio,
          setActiveOrganization: this.setActiveOrganization,
          setUserVersion: this.setUserVersion,
          logoutUser: this.logoutUser,
          showToast: this.showToast,
          setToasts: this.setToasts,
          disableOrganizationSelect: this.state.disableOrganizationSelect,
          setDisableOrganizationSelect: this.setDisableOrganizationSelect,
          updatingProfile: this.state.updatingProfile,
          updatingTime: this.state.updatingTime,
          resetDisabledStates: this.resetDisabledStates,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const AppConsumer = AppContext.Consumer;

export default AppContext;
