import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingMemo, faBuildings } from '@fortawesome/pro-solid-svg-icons';
import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';
import AppContext from '../../contexts/app-context';
import EnergyTracerTable from '../../components/tables';
import PortfolioModal from '../../components/modals/PortfolioModal';
import { portfolioOrganizationFormatter } from './helpers';
import Loader from '../../components/Loader';
import SmallerLoader from '../../components/SmallerLoader';
import AddOrganizationModal from '../../components/modals/ResourceModals/AddOrganizationModal';
import FilterInput from '../../components/FilterInput';
import ConfirmationModal, {
  useConfirm,
} from '../../components/modals/ConfirmationModal';

const styles = {
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

export default function PortfolioTable(props) {
  const { selectedPage, setSelectedPage, setPortfolioInfo, refreshFlag } =
    props;

  const {
    setActivePortfolio,
    userSelectedPortfolio,
    userSelectedOrganization,
    setActiveOrganization,
    showToast,
    setDisableOrganizationSelect,
    userSubscriptions,
  } = useContext(AppContext);

  const history = useHistory();

  const [portfolios, setPortfolios] = useState(null);
  const [organizationLookup, setOrganizationLookup] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);

  const [selectedPortfolioId, setActivePortfolioId] = useState('-1');
  const [isSelecting, setIsSelecting] = useState(-1);

  const [organizationFilterValue, setOrganizationFilterValue] = useState('');
  const [portfolioFilterValue, setPortfolioFilterValue] = useState('');

  const [confirmationModalProps, withConfirm] = useConfirm();

  useEffect(() => {
    setActivePortfolioId(userSelectedPortfolio.id.toString());
  }, [userSelectedPortfolio.id]);

  useEffect(() => {
    if (
      portfolios &&
      organizationLookup &&
      Object.keys(organizationLookup).length > 0
    ) {
      const portfolio_info = portfolios.find(
        (portfolio) => portfolio.id === userSelectedPortfolio.id
      );
      if (portfolio_info) {
        const portfolio_organization_ids = Object.keys(portfolio_info.pos);
        const portfolio_organization = portfolio_organization_ids.find(
          (org) => org.id === userSelectedOrganization.id
        );
        if (!portfolio_organization) {
          if (portfolio_organization_ids.length > 0) {
            let portfolio_organizations = [];
            portfolio_organization_ids.forEach((org_id) => {
              if (org_id in organizationLookup) {
                portfolio_organizations.push(organizationLookup[org_id]);
              }
            });
            const ordered_orgs = sortBy(portfolio_organizations, ['name']);
            setActiveOrganization({
              id: ordered_orgs[0].id,
              name: ordered_orgs[0].name,
            });
          } else {
            setActiveOrganization({
              id: null,
              name: '',
            });
          }
        }
      }
    }
  }, [
    userSelectedOrganization.id,
    userSelectedPortfolio.id,
    portfolios,
    organizationLookup,
    setActiveOrganization,
  ]);

  const getPortfolios = useCallback(() => {
    setIsLoading(true);
    ApiServiceServerless.get('/portfolios')
      .then((res) => {
        setPortfolios(res.data.portfolios);
        let organization_lookup = {};
        res.data.organizations.forEach((org) => {
          organization_lookup[org.id] = org;
        });
        setOrganizationLookup(organization_lookup);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleToggleActive = (row) => {
    setIsSelecting(row.id);
    if (row.selected) {
      setActivePortfolio(
        { id: -1, name: '' },
        false,
        () => setActiveCallback('-1'),
        () => setActiveCallbackError()
      );
    } else {
      setActivePortfolio(
        row,
        false,
        () => setActiveCallback(row.id.toString()),
        () => setActiveCallbackError()
      );
    }
  };

  const setActiveCallback = (id) => {
    setActivePortfolioId(id);
    setIsSelecting(-1);
    showToast('success', 'Success', 'Active Portfolio Updated');
  };

  const setActiveCallbackError = () => {
    setIsSelecting(-1);
    showToast('danger', 'Error', 'Active Portfolio Update Failed');
  };

  useEffect(() => {
    getPortfolios();
  }, [getPortfolios]);

  useEffect(() => {
    if (refreshFlag > 0 && selectedPage === 'portfolio-table') {
      getPortfolios();
    }
  }, [getPortfolios, refreshFlag, selectedPage]);

  const setPortfolio = (row) => {
    setSelectedPage('edit-portfolio');
    setPortfolioInfo({ id: row.id, name: row.name });
  };

  useEffect(() => {
    setDisableOrganizationSelect(isLoading);
  }, [isLoading, setDisableOrganizationSelect]);

  const createTableData = (
    portfolios,
    organizationLookup,
    selectedPortfolioId,
    isSelecting,
    portfolioFilterValue
  ) => {
    const tableData = [];
    portfolios = sortBy(
      portfolios.filter((portfolio) =>
        portfolio.name
          .toLowerCase()
          .includes(portfolioFilterValue.toLowerCase())
      ),
      ['is_summary', 'name']
    );

    portfolios.forEach((portfolio) => {
      let portfolio_orgs = [];
      Object.keys(portfolio.pos).forEach((po) => {
        portfolio_orgs.push({
          id: po,
          name: po in organizationLookup ? organizationLookup[po].name : '',
        });
      });
      portfolio_orgs = sortBy(portfolio_orgs, ['name']);

      let portfolio_row = {
        id: portfolio.id,
        name: portfolio.name,
        organizations: [],
        selected: portfolio.id.toString() === selectedPortfolioId,
        isSelecting: isSelecting,
      };

      portfolio_row['organizations'] = portfolio_orgs;

      tableData.push(portfolio_row);
    });
    return tableData;
  };

  const columnStyle = (row, bordered = false) => {
    const styles = {
      row: {
        borderBottomWidth: 0,
        borderTopWidth: 0,
      },
      firstOrgRow: {
        borderTopWidth: 1,
      },
    };
    let style = bordered ? {} : styles.row;
    if (row['name']) style = { ...style, ...styles.firstOrgRow };
    return style;
  };

  const getStyles = (disabled) => {
    return {
      checkStyle: {
        fontSize: '21px',
        margin: '0 0.35em',
        verticalAlign: 'text-bottom',
        cursor: disabled ? 'none' : 'pointer',
        color: 'var(--et_tab_grey)',
        opacity: disabled ? '0.5' : '1.0',
      },
    };
  };

  const tableCols = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row) => (
        <div
          className={row.id !== -1 ? 'et-link' : ''}
          onClick={
            row.id !== -1
              ? () => {
                  setPortfolio(row);
                }
              : () => {}
          }
        >
          {row.name}
        </div>
      ),
      style: (_cell, row) => columnStyle(row),
    },
    {
      dataField: 'organizations',
      text: 'Organizations',
      formatExtraData: {
        organizationFilterValue: organizationFilterValue.toLowerCase(),
        userSelectedPortfolio: userSelectedPortfolio,
      },
      formatter: (cell, row, rowIndex, formatExtraData) =>
        portfolioOrganizationFormatter(
          row,
          setActiveOrganization,
          setActivePortfolio,
          withConfirm,
          history,
          formatExtraData
        ),
    },
    {
      dataField: 'selected',
      text: 'Active',
      formatter: (cell, row) => (
        <>
          {row.id !== -1 && (
            <>
              {row.isSelecting === row.id ? (
                <div style={{ paddingLeft: '5px' }}>
                  <SmallerLoader />
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  {row.selected ? (
                    <FontAwesomeIcon
                      style={getStyles(row.isSelecting !== -1).checkStyle}
                      icon={faSquareCheck}
                      title={'Unset as Active'}
                      onClick={() => handleToggleActive(row)}
                      disabled={row.isSelecting !== -1}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={getStyles(row.isSelecting !== -1).checkStyle}
                      icon={faSquare}
                      title={'Set as Active'}
                      onClick={() => handleToggleActive(row)}
                      disabled={row.isSelecting !== -1}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  const handleRefreshTable = useCallback(() => {
    if (portfolios && organizationLookup) {
      const tableData = createTableData(
        portfolios,
        organizationLookup,
        selectedPortfolioId,
        isSelecting,
        portfolioFilterValue
      );
      setTableData(tableData);
    }
  }, [
    portfolios,
    organizationLookup,
    isSelecting,
    selectedPortfolioId,
    portfolioFilterValue,
  ]);

  useEffect(() => {
    handleRefreshTable();
  }, [handleRefreshTable]);

  return (
    <>
      {isLoading ? (
        <Loader style={{ marginTop: '8vh' }} />
      ) : (
        <div>
          <Row>
            <FilterInput
              label='Filter Portfolio'
              setFilterValue={setPortfolioFilterValue}
              size={3}
            />
            <FilterInput
              label='Filter Organization'
              setFilterValue={setOrganizationFilterValue}
              size={3}
            />

            <Col md={6}>
              <div
                style={{
                  marginTop: '1em',
                  marginBottom: '1em',
                  textAlign: 'right',
                }}
              >
                <FontAwesomeIcon
                  style={styles.iconStyle}
                  icon={faBuildingMemo}
                  title={'Add Portfolio'}
                  onClick={() => setShowPortfolioModal(true)}
                />
                <FontAwesomeIcon
                  style={styles.iconStyle}
                  icon={faBuildings}
                  title={'Add Organization'}
                  onClick={() => setShowOrganizationModal(true)}
                />
              </div>
            </Col>
          </Row>

          <EnergyTracerTable
            keyField='id'
            data={tableData}
            columns={tableCols}
          />
        </div>
      )}

      <PortfolioModal
        setPortfolios={setPortfolios}
        showToast={showToast}
        show={showPortfolioModal}
        onHide={() => setShowPortfolioModal(false)}
      />
      <AddOrganizationModal
        show={showOrganizationModal}
        onHide={() => {
          setShowOrganizationModal(false);
        }}
        portfolios={portfolios}
        setPortfolios={setPortfolios}
        setOrganizationLookup={setOrganizationLookup}
        userSubscriptions={userSubscriptions}
        showToast={showToast}
      />
      <ConfirmationModal
        {...confirmationModalProps}
        confirmText='Confirm'
        submitVariant={'success'}
      />
    </>
  );
}
