import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import ApiServiceServerless from '../../xhr_libs';
import Loader from '../../components/Loader';
import UserSubscription from './UserSubscription';

export default function UserSubscriptions(props) {
  const { showToast, userOrganizations } = props;

  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscriptionId, setActiveSubscriptionId] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeSubscriptionId !== null) {
      let subscription = subscriptions.find(
        (subscription) => subscription.id === activeSubscriptionId
      );
      setActiveSubscription(subscription ? subscription : null);
    } else {
      setActiveSubscription(null);
    }
  }, [subscriptions, activeSubscriptionId]);

  const getSubscriptions = useCallback(() => {
    setIsLoading(true);
    ApiServiceServerless.get('/subscriptions')
      .then((res) => {
        setSubscriptions(res.data);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Row>
            <Col style={{ display: 'inline-flex' }}>
              <Row>
                <Form inline style={{ marginBottom: '0.5em' }}>
                  <Form.Label
                    style={{
                      display: 'block',
                      marginRight: '0.5em',
                      marginLeft: '0.5em',
                    }}
                  >
                    Subscription:
                  </Form.Label>
                  <Form.Control
                    style={{ float: 'right' }}
                    as='select'
                    size='sm'
                    value={activeSubscriptionId ? activeSubscriptionId : ''}
                    onChange={(e) => {
                      let pid = e.target.value;
                      setActiveSubscriptionId(parseInt(pid));
                      setActiveSubscription(null);
                    }}
                  >
                    <option hidden value={'null'} key={'null-opt'}>
                      Select a subscription
                    </option>
                    {subscriptions.length === 0 && (
                      <option disabled value={'dis'} key={'null-dis'}>
                        No Subscriptions
                      </option>
                    )}
                    {subscriptions.map((subscription) => (
                      <option
                        key={`subscription-opt-${subscription.id}-${subscription.subscription_type}`}
                        value={subscription.id}
                      >
                        {subscription.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form>
              </Row>
            </Col>
          </Row>

          {activeSubscriptionId !== null && activeSubscription !== null && (
            <UserSubscription
              activeSubscription={activeSubscription}
              userOrganizations={userOrganizations}
              showToast={showToast}
            />
          )}
        </>
      )}
    </>
  );
}
