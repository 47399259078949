import React from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

import UserEditForm from './UserEditForm';
import UserResetPassword from './UserResetPassword';
import { useTabUrl } from '../../helpers/tracked';

import Helmet from 'react-helmet';
import UserSubscriptions from './UserSubscriptions';
const pageTitle = 'User Profile';

export default function Profile(props) {
  const { showToast, userOrganizations } = props;

  const [activeTab, setActiveTab] = useTabUrl('user-settings');

  return (
    <Container className='et-container et-main-tab-content' tabtype='medium'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Tabs
        id='settings-tabs'
        activeKey={activeTab}
        onSelect={setActiveTab}
        className='et-main-tabs'
      >
        <Tab eventKey='user-settings' title='User Info'>
          <Row style={{ marginTop: '16px' }}>
            <Col />
            <Col lg={6} sm={10}>
              <UserEditForm />
            </Col>
            <Col />
          </Row>
        </Tab>

        <Tab eventKey='reset-password' title='Change Password'>
          <Row style={{ marginTop: '16px' }}>
            <Col />
            <Col lg={6} sm={10}>
              <UserResetPassword />
            </Col>
            <Col />
          </Row>
        </Tab>
        <Tab eventKey='subscriptions' title='Subscriptions'>
          <Row style={{ marginTop: '16px' }}>
            <Col>
              <UserSubscriptions
                showToast={showToast}
                userOrganizations={userOrganizations}
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <div style={{ marginBottom: '20px' }} />
    </Container>
  );
}
