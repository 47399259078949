import React, { useState, useContext } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import AddOrganizationModal from '../modals/ResourceModals/AddOrganizationModal';
import AppContext from '../../contexts/app-context';

const styles = {
  button: {
    position: 'absolute',
    bottom: '1em',
    right: '2em',
  },
  alert: {
    padding: '1em',
    marginTop: '0.5em',
  },
};

function NavigationAlert(props) {
  const { mainText, buttonText, location, resource_type } = props;

  const { userPortfolios, showToast, userSubscriptions } =
    useContext(AppContext);

  const history = useHistory();

  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  return (
    <>
      <Alert variant='primary' style={styles.alert}>
        {mainText}
        {resource_type === 'organization' && (
          <Button
            style={styles.button}
            variant='success'
            onClick={() => setShowOrganizationModal(true)}
          >
            {buttonText}
          </Button>
        )}
        {resource_type === 'other' && (
          <Button
            style={styles.button}
            variant='success'
            onClick={() => history.push(location)}
          >
            {buttonText}
          </Button>
        )}
      </Alert>
      <AddOrganizationModal
        show={showOrganizationModal}
        onHide={() => {
          setShowOrganizationModal(false);
        }}
        portfolios={userPortfolios}
        setPortfolios={() => {}}
        setOrganizationLookup={() => {}}
        userSubscriptions={userSubscriptions}
        showToast={showToast}
      />
    </>
  );
}

NavigationAlert.defaultProps = {
  resource_type: 'other',
};

export default NavigationAlert;
