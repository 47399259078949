import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { sortBy } from 'lodash';

import AddEntityActionButtons from '../forms/AddEntityActionButtons';
import { ApiServiceServerless } from '../../xhr_libs';

export default function AddSubscriptionOrganizationModal(props) {
  const {
    show,
    onHide,
    subscription_id,
    userOrganizations,
    subscriptionOrganizationIds,
    setSubscriptionOrganizations,
    showToast,
  } = props;

  const [organizationId, setOrganizationId] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const clearForm = () => {
    setOrganizationId('');
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();

    ApiServiceServerless.post(
      `/subscriptions/subscription_organization/${subscription_id}/${organizationId}`
    )
      .then((res) => {
        showToast('success', 'Success', 'Organization added to subscription');
        setSubscriptionOrganizations((prev) => [
          ...prev,
          {
            id: organizationId,
            name: userOrganizations.find((org) => org.id === organizationId)
              .name,
          },
        ]);
        onHide();
        clearForm();
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        onHide();
        setIsSaving(false);
        throw err;
      });
  };

  const handleCancel = () => {
    onHide();
    clearForm();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add Organization to Subscription'}
        </Modal.Header>
        <Form
          noValidate
          onSubmit={handleSubmit}
          style={{
            textAlign: 'left',
          }}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Organization </Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      setOrganizationId(parseInt(e.target.value))
                    }
                  >
                    <option key={'null-val'} value={''} hidden></option>
                    {userOrganizations.filter(
                      (org) => !subscriptionOrganizationIds.includes(org.id)
                    ).length === 0 && (
                      <option key={'null-null'} value={''} disabled>
                        No available organizations
                      </option>
                    )}
                    {sortBy(userOrganizations, ['name'])
                      .filter(
                        (org) => !subscriptionOrganizationIds.includes(org.id)
                      )
                      .map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <AddEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={organizationId === null}
              submitShow={true}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
